@import "bootstrap/scss/mixins/banner";
@include bsBanner("");

@import "variables";	// Portal Custom variables
@import "mixins";		// Portal Custom mixins

// Your variable overrides
$enable-caret: false;	// For dropdowns
$enable-shadows: true;	// For dropdowns etc
$enable-dark-mode: false;	// For dark mode

$font-family-base: 'Open Sans', Helvetica, Arial, sans-serif;
$line-height-base: 1.428571429;	// TODO: Make it 1.5 and fix application spacing issues - This is temporary to keep everything matching for now

// Modals
$zindex-modal-backdrop: 1050;
$zindex-modal: 1055;
$modal-backdrop-bg: #666;
$modal-backdrop-opacity: 0.9;
$modal-transition: transform .15s ease-out;
$modal-content-border-radius: _rem(8px);
$modal-dialog-margin-y-sm-up: _rem(8px);
$modal-footer-border-color: $color-lighter-gray;
$modal-content-box-shadow-sm-up: 0 _rem(5px) _rem(15px) _rem(-5px) rgba(0, 0, 0, 0.3);
$modal-sm: 300px;
$modal-md: 600px;
$modal-lg: 730px;
$modal-xl: 980px;

// Tooltips
$tooltip-bg: $color-darker-gray;
$tooltip-opacity: 1;
$tooltip-font-size: _rem(13px);

// Tabs
$nav-link-hover-color: inherit;
$nav-link-transition: none;

// Dropdowns
$dropdown-padding-y: _rem(5px);
$dropdown-font-size: _rem(14px);
$dropdown-border-radius: _rem(4px);
$dropdown-link-color: $color-charcoal;
$dropdown-link-active-bg: $color-lightest-gray;
$dropdown-link-active-color: $color-charcoal;

$badge-color: unset; // To avoid badge class putting white color to area name

// scss-docs-start import-stack
// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
// @import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar";
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/accordion";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
// @import "bootstrap/scss/offcanvas";
// @import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack
