/*
	BUTTON ASSETS
*/
.button-basics {
	border-radius: $std-button-radius;
	font-weight: 400;
	border: 1px solid transparent;

	&.disabled, &:disabled, &:disabled:hover {
		opacity: 0.4;
		box-shadow: none;
		cursor: default;
	}

	&:focus, &:hover {
		outline: none;
		box-shadow: inset 0px _rem(-2px) 0 0 rgba(0, 0, 0, 0.2);
	}
}

.button-basics-outline {
	background-color: transparent;

	&:focus, &:hover {
		box-shadow: none;
	}
}

// TODO: Rename _button to something else when all button assets are complete
a._button { display: inline-block; text-align: center; }
._button, a._button {
	@extend .button-basics;

	padding: _rem(10px) _rem(14px);

	&._primary {
		color: $color-white;
		background-color: $color-button-primary-orange;

		&:active {
			background-color: $color-button-primary-orange-pressed;
		}
	}

	&._primary-outline {
		@extend .button-basics-outline;
		color: $color-button-primary-orange;
		border-color: $color-button-primary-orange;
		background-color: transparent;

		&:focus, &:hover {
			color: $color-button-primary-outline-orange-hover;
			border-color: $color-button-primary-outline-orange-hover;
		}
	}

	&._secondary {
		color: $color-white;
		background: $color-button-secondary-grey;

		&:active {
			background-color: $color-button-secondary-grey-pressed;
		}
	}

	&._secondary-outline {
		@extend .button-basics-outline;
		color: $color-button-secondary-outline-grey;
		border-color: $color-button-secondary-outline-border-grey;

		&:focus, &:hover {
			// color: $color-button-secondary-outline-grey-hover;
			border-color: $color-button-secondary-outline-grey-hover;
		}
	}

	&._green {
		color: $color-white;
		background: $color-button-green;

		&:active {
			background-color: $color-button-green-pressed;
		}
	}

	&._green-outline {
		@extend .button-basics-outline;
		color: $color-button-green;
		border-color: $color-button-green;
		background-color: transparent;

		&:focus, &:hover {
			color: $color-button-outline-green-hover;
			border-color: $color-button-outline-green-hover;
		}
	}

	&._blank {
		@extend .button-basics-outline;
		color: $color-button-secondary-grey;
		border-color: transparent;
		background-color: transparent;

		&:focus, &:hover {
			color: $color-button-secondary-outline-grey-hover;
		}
	}

	&._blank-primary {
		@extend .button-basics-outline;
		color: $color-green;
		border-color: transparent;
		background-color: transparent;

		&:focus, &:hover {
			color: $color-button-outline-green-hover;
		}

		&._dark-hover {
			&:hover, &:focus {
				background-color: rgba($color-green, 0.1);
			}
		}
	}

	&._charcoal {
		color: $color-white;
		background: $color-charcoal;

		&:active, &:hover {
			background-color: lighten($color-charcoal, 11);
		}
	}

	&._new-lighter-grey {
		color: $color-black;
		background: $color-lighter-gray;

		&._with-chevron .chevron-icon {
			color: $color-black;
		}

		&:hover {
			background-color: $color-new-light-gray;
		}
	}

	&._big {
		padding-top: _rem(13px);
		padding-bottom: _rem(13px);
	}

	&._small {
		padding: _rem(8px) _rem(14px);
	}

	&._smaller {
		padding: _rem(5px) _rem(10px);
	}

	&._tiny {
		padding: _rem(3px) _rem(6px);
	}

	&._minute {
		padding: _rem(1px) _rem(3px);
	}

	&._rounded {
		border-radius: _rem(40px);
	}

	&._no-side-padding {
		padding-left: 0;
		padding-right: 0;
	}

	&._grid-side-padding {
		padding-left: var(--gutter-width, $gutter-width);
		padding-right: var(--gutter-width, $gutter-width);
	}

	&._block {
		display: block;
		width: 100%;
		text-align: center;
	}

	&._fixed-large {
		width: _rem(200px);
	}

	&._fixed-medium {
		width: _rem(170px);
	}

	&._fixed-small {
		width: _rem(110px);
	}

	&._fixed-tiny {
		width: _rem(60px);
	}

	&._with-chevron {
		position: relative;

		.chevron-icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 15px;
			color: $color-white;
		}
	}
}


@media (min-width: 768px) {

	._button, a._button {
		padding: _rem(10px) _rem(20px);
	}

}



/*
	BUTTON GROUP
	============
	SAMPLE:
	<div class="button-group" btnRadioGroup [(ngModel)]="radioModel" [ngModelOptions]="{standalone: true}">
		<label class="_button _primary _smaller" btnRadio="1" tabindex="0">Option 1</label>
		<label class="_button _primary _smaller" btnRadio="2" tabindex="0">Option 2</label>
		<label class="_button _primary _smaller" btnRadio="3" tabindex="0">Option 3</label>
	</div>
*/
.button-group {
	line-height: 0;
	$main-color: $std-border-color;

	background-color: $main-color;
	border: 2px solid $main-color;
	border-radius: $std-button-radius;

    display: inline-block;
	user-select: none;
	white-space: nowrap;
	position: relative;
	vertical-align: middle;

	._button {
		font-weight: 600;
		line-height: 1;
		position: relative;
		box-shadow: none;
		display: inline-block;
		border-radius: $std-button-radius;
		color: $color-black;
		margin-left: _rem(1px);
		margin-right: _rem(1px);
		cursor: pointer;

		&.active {
			cursor: auto;
			background-color: $color-white;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		}

		& + ._button {
			margin-left: 2px;
		}

		& + ._button:before {
			content: '';
			display: inline-block;
			width: 1px;
			height: 50%;
			top: 25%;
			left: -3px;
			border-left: 1px solid darken($main-color, 5%);
			position: absolute;
		}

		// Hide separators before and after active button
		&.active._button::before, &.active + ._button::before {
			border-left: transparent;
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&.slightness {
		opacity: 0.4;
	}

	&.disabled {
		opacity: 0.4;

		._button {
			cursor: not-allowed;
		}
	}
}

/*
	CUSTOM RADIO/CHECKBOX CHECKMARK FIELD ASSET
	============
	SAMPLE:

	<label class="input-checkmark">
		<input name="radio-group" type="radio" />
		<span>Radio Choice</span>
	</label>

	<label class="input-checkmark">
		<input name="fieldname" type="checkbox" checked />
		<span>Checkbox Choice</span>
	</label>
*/
.input-checkmark {
	line-height: 1;
	padding-bottom: _rem(5px);
	position: relative;
	display: inline-block;

	$borderEmValue: 1px;

	> span, .info {
		position: relative;
		padding-left: 1.5em;
	}

	.info {
		display: block;
		font-size: 12px;
		margin-top: 5px;
		margin-left: 3px;
	}

	input[type="checkbox"], input[type="radio"] {
		opacity: 1;
		position: absolute;
		appearance: none;
		z-index: 1;

		& + span:before {
			content: '';
			background: #fff;
			box-shadow: 0 0 0 $borderEmValue $color-radio-border;
			display: block;
			width: 0.55em;
			height: 0.55em;
			border-radius: 50%;
			position: absolute;
			top: 0.0625em;
			left: 0.0625em;
			padding: 0.43em;
		}

		&:checked:before {
			font-family: 'Font Awesome 6 Pro';
			font-weight: 900;
			content: "\f058"; // \f00c
			color: $color-orange;
			line-height: 1;
			font-size: 1em;
			position: absolute;
			top: 0.06em;
			left: 0.005em;
			display: block;
		}

		&:focus + span:before {
			box-shadow: 0 0 0 $borderEmValue $color-radio-border;
		}

		&:hover + span:before {
			box-shadow: 0 0 0 $borderEmValue $color-orange;
		}
	}

	input[type="checkbox"], input[type="radio"], span {
		display: inline-block;
		cursor: pointer;
	}

	input[type="checkbox"]:disabled, input[type="radio"]:disabled {
		&:before {
			color: $color-faded-gray !important;
			opacity: 0.4;
		}

		&:hover + span::before {
			box-shadow: 0 0 0 $borderEmValue $color-faded-gray;
		}
	}

	input[type="checkbox"]:checked, input[type="radio"]:checked {
		&:hover + span:before, &:focus + span:before, & + span:before {
			box-shadow: none;
		}
	}
}

.input-checkmark.rounded-square {
	input[type="checkbox"], input[type="radio"] {
		& + span:before {
			border-radius: 1pt;
		}

		&:checked:before {
			font-family: 'Font Awesome 6 Pro';
			font-weight: 900;
			content: "\f14a"; // \f00c
			font-size: 0.999em;
			top: 1px;
		}
	}
}


/*
	CUSTOM FORM FIELD ASSET
	============
	SAMPLE:
	<form class="form-root">
		<div class="form-field" OPTIONAL-CLASS="has-error">
			<label for="profile-first-name">First Name</label>
			<input type="text" id="profile-first-name" autocomplete="none" />
			<span class="error-message">Enter first name here</span>
			<span class="info">Enter first name here</span>
		</div>
	</form>
*/
.form-field {
	display: block;
	padding: _rem(9px);
	border: 1px solid #D7D7D7;
	margin-bottom: _rem(15px);
	border-radius: _rem(3px);
	color: $color-text;
	background-color: $color-white;

	&.inline {
		display: inline-block;
	}

	&.no-label {
		padding: _rem(11.5px) _rem(9px);
	}

	/* Apply on form-field class which contains a <select> */
	&.select {
		padding-top: 0;
		padding-bottom: 0;

		&:not(.medium) label {
			position: relative;
			top: 9px;
		}

		select {
			height: _rem(43px);
		}

		[disabled] {
			opacity: 0.5;
		}
	}

	&.disable {
		background-color: #f9f9f9;
		border-color: #e8e8e8;
		opacity: 0.9;

		label {
			opacity: 0.8;
			border-bottom-color: #e8e8e8;
		}
	}

	label {
		display: block;
		float: left;
		font-size: _rem(13px);
		margin-top: _rem(-18px);
		padding: _rem(2px) _rem(5px) _rem(5px) _rem(5px);
		letter-spacing: _rem(-0.14px);
		color: rgba(142,142,146,0.76);
		background: $color-white;
		line-height: 1;
		font-weight: 500;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow-y: hidden;
		border-radius: _rem(6px);
		margin-bottom: 0;
		border-bottom: 1px solid transparent;
	}

	input[type="text"],
	input[type="date"],
	input[type="datetime"],
	input[type="email"],
	input[type="number"],
	input[type="search"],
	input[type="time"],
	input[type="url"],
	input[type="password"],
	textarea,
	select {
		box-sizing: border-box;
		width: 100%;
		display: block;
		outline: none;
		border: none;
		height: _rem(23px);
		line-height: _rem(23px);
		font-size: _rem(13px);
		padding: 0 _rem(6px);
		background: transparent;
		color: $color-black;
		font-weight: 600;
	}

	select {
		background: url('/assets/images/down-arrow.png') no-repeat 99% 50%;
		background-size: _rem(13px);
		appearance: none;
		padding-right: 25px;
	}

	/*input[type="text"]:focus,
	input[type="date"]:focus,
	input[type="datetime"]:focus,
	input[type="email"]:focus,
	input[type="number"]:focus,
	input[type="search"]:focus,
	input[type="time"]:focus,
	input[type="url"]:focus,
	input[type="password"]:focus,
	textarea:focus,
	select:focus {
	}*/

	&.has-error {
		label {
			color: $color-blue;
		}

		border-color: $color-blue;

		span.error-message:not(:empty) + span.info {	/* hide info if error-message is not empty and error is showing */
			display: none;
		}

		span.error-message {
			display: block;
		}

		a {
			color: $color-white;
			text-decoration: underline;
		}
	}

	span.error-message p {
		padding: 0;
		margin: 0;
	}

	span.info, span.error-message {
		display: block;
		padding: _rem(3px) _rem(5px);
		margin: 0 _rem(-9px) _rem(-9px) _rem(-9px);
		text-align: center;
		font-size: _rem(11px);
		margin-top: _rem(3px);
		border-bottom-right-radius: 2px;
		border-bottom-left-radius: 2px;
	}

	&.no-label {
		span.info, span.error-message {
			margin-bottom: _rem(-11.5px);
		}
	}

	span.info {
		background: #f3f3f3;
		color: silver;
	}

	span.error-message {
		display: none;
		background: $color-blue;
		color: $color-white;
	}

	&.select.has-error span.error-message {
		margin-top: 0;
		margin-bottom: _rem(0px);
	}

	&.medium.has-error span.error-message {
		margin-bottom: _rem(-5px);
		margin-left: _rem(-5px);
		margin-right: _rem(-5px);
	}

	&.loading-data {
		background-image: url('/assets/images/dots.svg');
		background-repeat: no-repeat;
		background-size: _rem(55px);
		background-position: 50% 50%;

		// Incase its also a search field - show both images
		.search-field {
			@extend .search-field;
		}

		> input {
			opacity: 0.4;
		}

		&.has-error, &.has-info {
			background-position-y: -5px;
		}

		&.select {
			opacity: 0.8;
			background-color: #f3f3f3 !important;
		}

		&.loader-right {
			background-position-x: calc(100% - #{_rem(10px)});
		}
	}

	&.medium {
		padding: _rem(3px) _rem(5px) _rem(4px) _rem(5px);

		label {
			margin-top: _rem(-12px);
			font-size: _rem(12px);
			padding: _rem(2px) _rem(5px) _rem(1px) _rem(5px);
		}

		&.select {
			select {
				height: _rem(23px);
			}
		}
	}

	&.color-black {
		label {
			color: $color-black;
		}
	}

	.search-field {
		@extend .search-field;
	}

}

body.firefox .form-field {
	&.select select {
		padding-left: 1px;
	}
}


/* OVERRIDING CHROME AUTOFILL HIDEOUS COLOR */

@keyframes autofill-form-field {
	to {
		color: initial;
		background-color: $color-white !important;
		background-image: initial;
	}
}

.form-field input:-webkit-autofill,
.form-field input:-internal-autofill-previewed,
.form-field input:-internal-autofill-selected {
	animation-name: autofill-form-field;
	animation-fill-mode: both;
	animation-delay: 1s;
	box-shadow: 0 0 0 30px $color-white inset;
}


/*
	Pill-Label
	SAMPLE:
		<span class="label-pill">SAMPLE</span>
*/
.label-pill {
	font-size: 12px;
	line-height: 1.3;
	color: $color-dark-gray;
	border: 1px solid $color-lighter-gray;
	border-radius: 12px;
	padding: 2px 9px;
	display: inline-block;
	margin-right: 5px;

	&._inverse {
		color: $color-white;
		background-color: $color-gray;
		border-color: $color-gray;
	}

	&._filled {
		background-color: $color-lighter-gray;
		border-color: $color-lighter-gray;

		&._green {
			background-color: rgba($color-green, 0.10);
			color: $color-green;
			border: 0;
		}
	}

	&._small {
		font-size: 11px;
		padding: 2px 8px;
	}

	&._smaller {
		padding: 0 7px 1px;
	}

	&._big {
		font-size: 13px;
		padding: 5px 14px;
		border-radius: 50px;
	}

	&:last-child, &.no-margin {
		margin-right: 0;
	}
}
