@use 'sass:math';

$baseline-px: 16px;
/**
	- PX to REM convertor
	- Usage - @include rem('padding', 16px 0 0 0);
**/
@mixin rem($property, $px-values) {
	// Convert the baseline into rems
	$baseline-rem: $baseline-px / 1rem;
	
	// Print the first line in pixel values
	#{$property}: $px-values;
	
	// If there is only one (numeric) value, return the property/value line for it.
	@if type-of($px-values) == "number" {
		#{$property}: $px-values / $baseline-rem; 
	} @else {
		// Create an empty list that we can dump values into
		$rem-values: unquote("");
		@each $value in $px-values {
			// If the value is zero or a string or a color, return unchanged input
			@if $value == 0 or type-of($value) == "string" or type-of($value) == "color" {
				$rem-values: append($rem-values, $value); }
			@else {
				$rem-values: append($rem-values, $value / $baseline-rem); 
			} 
		}
		// Return the property and its list of converted values
		#{$property}: $rem-values; 
	} 
}


/**
	- PX to REM convertor
	- Usage - padding: _rem(16px 0 0 0);
**/
@function _rem($px-values) {
	// Convert the baseline into rems
	$baseline-rem: math.div($baseline-px, 1rem);
	
	// If there is only one (numeric) value, return the property/value line for it.
	@if type-of($px-values) == "number" {
		@return math.div($px-values, $baseline-rem);
	} @else {
		// Create an empty list that we can dump values into
		$rem-values: unquote("");
		@each $value in $px-values {
			// If the value is zero or a string or a color, return unchanged input
			@if $value == 0 or type-of($value) == "string" or type-of($value) == "color" {
				$rem-values: append($rem-values, $value); }
			@else {
				$rem-values: append($rem-values, $value / $baseline-rem); 
			} 
		}
		// Return the property and its list of converted values
		@return $rem-values; 
	} 
}
