@import "main";

label {
	font-weight: normal;
}

input {
	border: 0;
}

input[type="text"] {
	background: #F2F2F2;
	border-radius: _rem(3px);
	color: $color-black;
	font-weight: 600;
}

// Remove box-shadow style in mobile devices
.mobile input[type="text"], .mobile input[type="number"] {
	appearance: none;
}

// Hide arrows control for number type input
input[type="number"].no-arrows::-webkit-outer-spin-button,
input[type="number"].no-arrows::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"].no-arrows {
	-moz-appearance: textfield;
}

input:active, input:focus {
	outline: none;
}

button {
	border: 0;
}


/* FORMS & INPUTS
--------------------------------------*/

select {
	padding: 0.6rem 1.0rem;

	&.simple-transparent {
		background-color: transparent;
	}

	&.transparent {
		padding: 0;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background: none;
		border: none;
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}

	&.bold {
		font-weight: 700;
	}
}

.light-placeholder::-webkit-input-placeholder {
	font-weight: 100;
}
.light-placeholder::-moz-placeholder {
	font-weight: 100;
}
.light-placeholder:-ms-input-placeholder {
	font-weight: 100;
}
.light-placeholder:-moz-placeholder {
	font-weight: 100;
}


/* OVERRIDING CHROME AUTOFILL HIDEOUS COLOR
--------------------------------------*/

@keyframes autofill-default {
    to {
        color: inherit;
        background-color: #F2F2F2;
		background-image: initial;
    }
}

input:-webkit-autofill {
    animation-name: autofill-default;
    animation-fill-mode: both;
}
