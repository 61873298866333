@use 'sass:math';

// Import files
@import "bootstrap-custom";
@import "main";
@import "components";
@import "helpers";
@import "material-styles";
@import "modals";
@import "form-controls";
@import "headings";


:root {
	--gutter-width: #{_rem(5px)};
	--gutter-width-fixed: #{_rem(5px)};
	--gutter-width-2x: calc(var(--gutter-width, 5px) * 2);
	--gutter-width-4x: calc(var(--gutter-width, 5px) * 4);

	--body-background-color: #{$color-body-background};

    --header-height: 58px;
	--preview-bar-height: 48px;

	@for $i from 1 through length( $change-order-action-colors ) {
		$color: nth($change-order-action-colors, $i);

		--change-order-#{$i}-color: #{$color};
		--change-order-#{$i}-border-color: #{rgba($color, 50%)};
		--change-order-#{$i}-background-color: #{rgba($color, 7%)};
	}
}


html {
	font-size: $baseline-px; /* - 1;*/
}

html, body { height: 100vh; }

.container, .container-fluid {
	padding-right: var(--gutter-width, $gutter-width);
	padding-left: var(--gutter-width, $gutter-width);
	max-width: 1200px;
	margin: auto;
}

.row {
	margin-right: 0;
	margin-left: 0;

	&.negative {
		margin-right: calc(-1 * var(--gutter-width, $gutter-width));
		margin-left: calc(-1 * var(--gutter-width, $gutter-width));
	}
}

.row .row {
	margin-right: calc(-1 * var(--gutter-width, $gutter-width));
	margin-left: calc(-1 * var(--gutter-width, $gutter-width));
}

[class*='col-'] {
	padding-right: var(--gutter-width, $gutter-width);
	padding-left: var(--gutter-width, $gutter-width);
}


/* BASE
--------------------------------------*/
body {
	background-color: var(--body-background-color, $color-body-background);
	font-family: $font-family-base;
	-webkit-font-smoothing: antialiased;
	font-size: _rem(14px);
	color: $color-text;
}
a {
	&, &:link, &:visited, &:hover, &:active {
		color: $color-text;
		text-decoration: none;
	}

	cursor: pointer;

	&.disabled {
		opacity: 0.8;
		pointer-events: none;
		cursor: default;
	}
}

.close {
	font-size: _rem($baseline-px * 1.31);
	font-weight: 100;
	line-height: 1;
	color: $color-black;
	opacity: 0.2;
	display: inline-block;
	cursor: pointer;
	user-select: none;
}

button.close {
	padding: 0;
	border: 0;
	cursor: pointer;
	background: transparent;
	-webkit-appearance: none
}

.pointer {
	cursor: pointer;
}



/* COLORS
--------------------------------------*/
.color-black, a.color-black {
	color: $color-black;
}

.color-charcoal, a.color-charcoal {
	color: $color-charcoal;
}

.color-white, a.color-white {
	color: $color-white;
}

.color-green, a.color-green {
	color: $color-green;
}

.color-orange, a.color-orange {
	color: $color-orange;
}

.color-red, a.color-red {
	color: $color-red;
}

.color-text, a.color-text {
	color: $color-text;
}

.dot {
	width: _rem(12px);
	height: _rem(12px);
	display: inline-block;
	line-height: 1;
	border-radius: 50%;
	border: 1px solid $color-faded-gray;

	&.medium {
		width: _rem(11px);
		height: _rem(11px);
	}

	&.color-grey {
		background: $color-faded-gray;
		border-color: $color-faded-gray;
	}

	&.color-yellow {
		background: $color-yellow;
		border-color: $color-yellow;
	}

	&.color-orange {
		background: $color-orange;
		border-color: $color-orange;
	}

	&.color-red {
		background: $color-red;
		border-color: $color-red;
	}

	&.color-green {
		background: $color-green;
		border-color: $color-green;
	}

	&.color-blank {
		background: $color-white;
	}

	&.color-black {
		background: $color-black;
		border-color: $color-black;
	}

	&.color-blue {
		background: $color-blue;
		border-color: $color-blue;
	}
}

.badge {
	&.color-red {
		background: $color-red;
	}

	&.white {
		background-color: $color-white;
		color: $color-orange;
	}
}


@for $i from 1 through length( $change-order-action-colors ) {

	.change-order-#{$i}-background-color {
		background: var(--change-order-#{$i}-background-color);
	}
}


/* HELPERS
--------------------------------------*/
.mt-1px { margin-top: 1px; }
.mt-2px { margin-top: 2px; }
.mt-3px { margin-top: 3px; }
.mt-4px { margin-top: 4px; }
.mt-5px { margin-top: 5px; }
.mt-6px { margin-top: 6px; }
.mt-7px { margin-top: 7px; }
.mt-8px { margin-top: 8px; }
.mt-9px { margin-top: 9px; }
.mt-10px { margin-top: 10px; }
.mt-11px { margin-top: 11px; }
.mt-12px { margin-top: 12px; }
.mt-13px { margin-top: 13px; }
.mt-14px { margin-top: 14px; }
.mt-15px { margin-top: 15px; }
.mt-20px { margin-top: 20px; }
.mt-30px { margin-top: 30px; }
.mt-40px { margin-top: 40px; }
.mt-50px { margin-top: 50px; }
.mt-60px { margin-top: 60px; }
.mt-70px { margin-top: 70px; }
.mt-80px { margin-top: 80px; }
.mt-90px { margin-top: 90px; }
.mt-100px { margin-top: 100px; }

.w100 {
	width: 100%;
}

.no-margin {
	margin: 0;
}

.no-padding {
	padding: 0;
}

.pad-top-10 {
	padding-top: _rem(10px);
}

.pad-bottom-10 {
	padding-bottom: _rem(10px);
}

.pad-left-grid {
	padding-left: var(--gutter-width, $gutter-width);
}

.pad-right-grid {
	padding-right: var(--gutter-width, $gutter-width);
}

.pad-top-grid {
	padding-top: var(--gutter-width, $gutter-width);
}

.pad-bottom-grid {
	padding-bottom: var(--gutter-width, $gutter-width);
}


.f0  { font-size: _rem(0px); }		// For inline-block elements space issues
.f8 { font-size: _rem(8px); }
.f9 { font-size: _rem(9px); }
.f10 { font-size: _rem(10px); }
.f11 { font-size: _rem(11px); }
.f12 { font-size: _rem(12px); }
.f13 { font-size: _rem(13px); }
.f14 { font-size: _rem(14px); }
.f15 { font-size: _rem(15px); }
.f16 { font-size: _rem(16px); }
.f17 { font-size: _rem(17px); }
.f18 { font-size: _rem(18px); }
.f19 { font-size: _rem(19px); }
.f20 { font-size: _rem(20px); }
.f21 { font-size: _rem(21px); }
.f22 { font-size: _rem(22px); }
.f23 { font-size: _rem(23px); }
.f24 { font-size: _rem(24px); }
.f25 { font-size: _rem(25px); }
.f26 { font-size: _rem(26px); }
.f27 { font-size: _rem(27px); }
.f28 { font-size: _rem(28px); }
.f29 { font-size: _rem(29px); }
.f30 { font-size: _rem(30px); }


.l-0 { line-height: 0; }
.l-1 { line-height: 0.1; }
.l-2 { line-height: 0.2; }
.l-3 { line-height: 0.3; }
.l-4 { line-height: 0.4; }
.l-5 { line-height: 0.5; }
.l-6 { line-height: 0.6; }
.l-7 { line-height: 0.7; }
.l-8 { line-height: 0.8; }
.l-9 { line-height: 0.9; }
.l1 { line-height: 1; }
.l2 { line-height: 2; }
.l3 { line-height: 3; }
.l4 { line-height: 4; }
.l5 { line-height: 5; }

.pull-down-1 { margin-bottom: _rem(-1px); }
.pull-down-2 { margin-bottom: _rem(-2px); }
.pull-down-3 { margin-bottom: _rem(-3px); }
.pull-down-4 { margin-bottom: _rem(-4px); }
.pull-down-5 { margin-bottom: _rem(-5px); }
.pull-down-6 { margin-bottom: _rem(-6px); }
.pull-down-7 { margin-bottom: _rem(-7px); }
.pull-down-8 { margin-bottom: _rem(-8px); }
.pull-down-9 { margin-bottom: _rem(-9px); }
.pull-down-10 { margin-bottom: _rem(-10px); }

.pull-down-hard-1,
.pull-down-hard-2,
.pull-down-hard-3,
.pull-down-hard-4,
.pull-down-hard-5,
.pull-down-hard-6,
.pull-down-hard-7,
.pull-down-hard-8,
.pull-down-hard-9,
.pull-down-hard-10,
.pull-up-hard-1,
.pull-up-hard-2,
.pull-up-hard-3,
.pull-up-hard-4,
.pull-up-hard-5,
.pull-up-hard-6,
.pull-up-hard-7,
.pull-up-hard-8,
.pull-up-hard-9,
.pull-up-hard-10  {
	position: relative;
}

.pull-down-hard-1 { bottom: _rem(-1px); }
.pull-down-hard-2 { bottom: _rem(-2px); }
.pull-down-hard-3 { bottom: _rem(-3px); }
.pull-down-hard-4 { bottom: _rem(-4px); }
.pull-down-hard-5 { bottom: _rem(-5px); }
.pull-down-hard-6 { bottom: _rem(-6px); }
.pull-down-hard-7 { bottom: _rem(-7px); }
.pull-down-hard-8 { bottom: _rem(-8px); }
.pull-down-hard-9 { bottom: _rem(-9px); }
.pull-down-hard-10 { bottom: _rem(-10px); }

.pull-up-1 { margin-top: _rem(-1px); }
.pull-up-2 { margin-top: _rem(-2px); }
.pull-up-3 { margin-top: _rem(-3px); }
.pull-up-4 { margin-top: _rem(-4px); }
.pull-up-5 { margin-top: _rem(-5px); }
.pull-up-6 { margin-top: _rem(-6px); }
.pull-up-7 { margin-top: _rem(-7px); }
.pull-up-8 { margin-top: _rem(-8px); }
.pull-up-9 { margin-top: _rem(-9px); }
.pull-up-10 { margin-top: _rem(-10px); }

.pull-up-hard-1 { top: _rem(-1px); }
.pull-up-hard-2 { top: _rem(-2px); }
.pull-up-hard-3 { top: _rem(-3px); }
.pull-up-hard-4 { top: _rem(-4px); }
.pull-up-hard-5 { top: _rem(-5px); }
.pull-up-hard-6 { top: _rem(-6px); }
.pull-up-hard-7 { top: _rem(-7px); }
.pull-up-hard-8 { top: _rem(-8px); }
.pull-up-hard-9 { top: _rem(-9px); }
.pull-up-hard-10 { top: _rem(-10px); }

.pre-line {
	white-space: pre-line;
}

.img-radius {
	border-radius: _rem(5px);
}


/* LAYOUT
--------------------------------------*/
::-webkit-scrollbar {
	width: 7px;		// For vertical
	height: 7px; 	// For horizontal
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: rgba(204, 204, 204, 0.0);
	transition: background-color 0.1s ease-in-out;
}

::-webkit-scrollbar-track {
	border-radius: 5px;
	background-color: transparent;
	box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.01);
}

:hover::-webkit-scrollbar-thumb, :focus::-webkit-scrollbar-thumb {
	background-color: rgba(204, 204, 204, 0.6);
	transition: background-color 0.1s ease-in-out;
}

.main-wrap {
	height: 100%;
}


body.modal-open {
	.main-wrap {
		overflow: hidden;
	}

	&.mobile {
		.main-wrap, main {
			-webkit-overflow-scrolling: auto;
		}
	}

	main, .main-container, .modal-content {
		overflow-x: hidden;
	}
}


main {
	overflow-y: scroll;
	overflow-x: auto;
	flex: 20;
	width: 100%;
	-webkit-overflow-scrolling: touch;		// To make the touch devices feel fluid when scrolled
}

.main-container {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
}

hr {
	margin-top: _rem(20px);
	margin-bottom: _rem(20px);
	border: 0;
	border-top: 1px solid #eeeeee; // Default color for HR
	opacity: 1; // Bootstrap defaults it to 0.25

	&.medium {
		border-top-color: #D7D7D7;
	}

	&.dark {
		border-top-color: #d2d2d2;
	}

	&.class {
		border-top-color: #a7a7a7;
	}
}

.cdk-overlay-container {
	z-index: $zindex-modal;		// Same as modal 1050
}

/* MODULES
--------------------------------------*/

.alert {
	padding-top: _rem(10px);
	padding-bottom: _rem(10px);
	font-size: _rem(14px);
	position: relative;
}

.alert.alert-danger {
	background-color: $color-red;
	border-color: $color-red;
	color: $color-white;
}

.alert.alert-success {
	background-color: $color-green;
	border-color: $color-green;
	color: $color-white;
}

.alert.alert-warning {
	background-color: #ff783a;
	border-color: #ff783a;
	color: $color-white;
}

.alert.alert-dismissible {
	.close {
		opacity: 1;
		top: 50%;
		right: _rem(10px);
		transform: translateY(-50%);
		padding: 0;

		&:before {
			position: absolute;
			content: '\f00d';
			font-family: 'Font Awesome 6 Pro';
			font-weight: 700;
			color: $color-white;
			font-size: 13px;
			top: 50%;
			transform: translate(-50%, calc(-50% + 1px));
		}

		&:hover {
			color: $color-white;
			opacity: 0.9;
		}
	}

	.close:focus {
		outline: none;
	}
}

.global-notification-container {
	position: fixed;
	right: calc(var(--gutter-width, $gutter-width) * 2);
	top: _rem(90px);
	z-index: 1500;
	width: _rem(300px);

	> div {
		text-align: right;
	}

	.alert {
		box-shadow: 0px 1px 30px 0px rgba(0,0,0,0.3);
		margin-bottom: _rem(10px);
	}
}

// For Payment Request and Change Orders
.entity-tile {
	position: relative;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	background-color: $color-white;
	border-radius: $std-border-radius;
	padding: _rem(18px) _rem(14px);
	margin-bottom: _rem(10px);
	box-shadow: 0 _rem(3px) _rem(10px) 0 rgba($color-black, 0.05);
	transition: box-shadow 0.2s;

	.status-line {
		width: _rem(4px);
		height: calc( 100% - 19px );
		position: absolute;
		left: 9px;
		top: 50%;
		border-radius: 4px;
		transform: translateY(-50%);
		background-color: $color-gray;
	}

	.client-details {
		flex: auto;
		max-width: _rem(500px);

		.entity-number {
			color: $color-gray;
			font-size: _rem(12px);
			letter-spacing: _rem(-0.16px);
		}

		.title {
			margin-top: _rem(-2px);
			color: $color-black;
			font-size: _rem(18px);
			font-weight: 700;
			letter-spacing: _rem(-0.21px);
		}

		.title-bullet {
			margin: 0px 10px;
			position: relative;
			top: _rem(-3px);
			font-size: _rem(10px);
		}

		.other-details {
			max-width: calc(100% - 10px);
			font-size: _rem(15px);
			color: $color-charcoal;
			letter-spacing: _rem(-0.2px);
			margin-top: _rem(2px);
		}
	}

	.status-details {
		margin-top: _rem(20px);
		display: flex;
		align-items: center;
		justify-content: space-between;

		/* .date-status {
			font-size: _rem(13px);
			color: $color-gray;
			white-space: nowrap;
		} */

		.entity-status {
			margin-left: auto;

			.status-button {
				width: _rem(170px);
				padding: _rem(8px) _rem(15px) _rem(7px);
				font-size: _rem(13px);
				color: $color-gray;
				letter-spacing: _rem(-0.24px);
				border: _rem(1px) solid $color-lighter-gray;
				border-radius: _rem(20px);
				text-align: left;
				text-transform: capitalize;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				background: $color-white;

				.status-button-bullet {
					position: relative;
					margin: 0 _rem(8px);
					top: _rem(-3px);
					font-size: _rem(5px);
				}
			}

			.date-stamp {
				font-size: 9px;
				color: $color-faded-gray;
				position: relative;
				top: _rem(18px);
				margin-top: _rem(-13px);	// Height of the element
				text-align: center;
			}
		}
	}
}

@media (min-width: 768px) {
	.entity-tile {
		flex-direction: row;
		padding: _rem(17px) _rem(37px) _rem(19px) _rem(39px);

		.status-details {
			margin-top: 0;

			.entity-status {
				margin-left: _rem(39px);
			}
		}
	}

}


@media (min-width: 992px) {
	/*html {
		//font-size: $baseline-px;
	}*/

	.global-notification-container {
		top: _rem(80px);
	}
}


@media (min-width: 1200px) {

	// Change the grid gutter width - For some reason mixens and variables not working in :root
	:root {
		--gutter-width: 0.625rem; //_rem(10px);
	}

	.row {
		&.fixed-side-padding {
			[class*='col-']:first-child {
				padding-left: calc(var(--gutter-width-fixed, 5px) * 2);
			}

			[class*='col-']:last-child {
				padding-right: calc(var(--gutter-width-fixed, 5px) * 2);
			}
		}
	}

	body.chrome, body.safari {
		br.big-grid, br.grid, br.small-grid, br.tiny-grid {
			margin-bottom: 0 !important;
		}
	}

}
