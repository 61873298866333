@import "main";

/*
body.edge.mobile .modal-dialog,
body.chrome.mobile .modal-dialog,
body.safari.mobile .modal-dialog {
	&.mobile-modal-fullscreen > .modal-content > .modal-header + .modal-body {
		height: calc(85vh - 56px);			// Subtract header height - 85 patch required for safari
	}
}
*/

.modal-content {
	transform: translateZ(0); 	// To lock position: fixed into viewport (for loaders etc)

	.modal-header + .modal-body > app-loader.in-modal {
		top: 57px;	// Subtract header height from app-loader if header is present
	}
}

.modal-dialog {
	width: 100%;

	--bs-modal-padding: #{_rem(5px)};
}

.modal-dialog {

	.modal-body,
	.modal-body > form {
		overflow: auto;
		height: 100%;
		-webkit-overflow-scrolling: touch;
	}

	/*> .modal-content > .modal-header + .modal-body {
		// height: calc(100vh - 56px);			// Subtract header height
	}*/

	&.black-modal, &.white-modal {
		> .modal-content > .modal-header {
			background-color: $color-charcoal;
			color: $color-white;
			position: relative;

			.back {
				color: $color-white;
				position: absolute;
				left: _rem(10px);
				top: calc(50% - #{_rem(17px)});
				background-color: transparent;
				border: 0;
				cursor: pointer;
				-webkit-appearance: none;
				user-select: none;
				line-height: 1;
				font-size: _rem(30px);
				outline: none;
				display: inline-block;
				padding: 0 _rem(10px) _rem(5px) _rem(5px);

				i {
					height: _rem(17px);
					line-height: 0.7;

					&:before {
						line-height: 0.7;
					}
				}
			}

			.close {
				color: $color-white;
				opacity: 1;
				position: absolute;
				top: calc(50% - #{_rem(14px)});
				right: _rem(16px);
				outline: none;
				font-size: _rem(30px);
			}

			.modal-title {
				font-size: _rem(18px);
			}
		}

		&.header-green {
			> .modal-content > .modal-header {
				background-color: $color-green;
			}

			> .modal-content {
				border-color: transparent;
			}
		}
	}

	&.white-modal {
		> .modal-content > .modal-header {
			background-color: transparent;
			color: $color-gray;
			border: 0;

			.close, .back {
				padding: 0;
				height: _rem(33px);
				width: _rem(33px);
				top: calc(50% - _rem(10px));
				color: $color-black;
				border-radius: 50%;
				overflow: hidden;

				&:hover {
					background-color: $color-lighter-gray;
				}
			}

			.back {
				left: _rem(15px);
				font-size: _rem(15px);

				i {
					line-height: 1.3;
				}
			}

			.close {
				right: _rem(15px);
				font-size: _rem(30px);
			}
		}
	}

	&.black-modal.dark-mode {
		.modal-content {
			background-color: $color-charcoal;
			color: $color-white;
		}

		.modal-header {
			border-bottom: 0;
		}
	}

	/*
		Should be used with separate .wrap <div class="wrap"></div> and <footer></footer> inside .modal-body
	*/
	&.footer-stick {
		.modal-body,
		.modal-body > form {
			display: flex;
			flex-direction: column;
		}

		.modal-body > form {
			flex: 1;
		}

		footer {
			margin-top: auto;
		}
	}
}

body.modal-open {
	&.os-windows {
		padding-right: $scrollbar-width !important;	// Needed here to get rid of layout shift when modal opens
	}

	.modal-content {
		overflow-x: hidden;
	}
}

.modal-dialog.height-big > .modal-content > .modal-body {
	height: inherit;
}

.modal-header.slim {
	padding: 12px;
}


@media (min-width: 576px) {
	.modal-md {
		// Keep modal-md width same as declared - otherwise bootstrap shrinks it on smaller screens
		--bs-modal-width: #{$modal-md};
	}
}

@media (min-width: 768px) {
	.modal-lg {
		// Keep modal-lg width same as declared - otherwise bootstrap shrinks it on smaller screens
		--bs-modal-width: #{$modal-lg};
	}

	.modal-dialog {
		--bs-modal-padding: #{_rem(15px)};

		> .modal-content > .modal-body {
			overflow: auto;
			max-height: _rem(600px);
		}

		&.variable-desktop-height > .modal-content > .modal-body {
			max-height: inherit;
		}

		&.height-big > .modal-content > .modal-body {
			max-height: calc(100vh - 58px);
		}
	}

	.modal-dialog.height-smaller > .modal-content > .modal-body {
		min-height: _rem(550px);
	}

	.modal-dialog.height-standard > .modal-content > .modal-body {
		min-height: _rem(600px);
	}

	.modal-dialog.height-bigger > .modal-content > .modal-body {
		height: _rem(689px);
		max-height: 100%;
	}
}

@media (min-width: 992px) {
	.modal-xl {
		// Keep modal-xl width same as declared - otherwise bootstrap shrinks it on smaller screens
		--bs-modal-width: #{$modal-xl};
	}
}

@media (min-width: 1200px) {
	.modal-dialog.height-big > .modal-content > .modal-body {
		height: _rem(660px);
		max-height: 100%;
	}
}
