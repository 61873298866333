@import "main";


h1.std, .h1.std {
	font-size: _rem(52px);
	letter-spacing: _rem(-1.6px);
	line-height: _rem(57px);	// Almost 1.1
}

h2.std, .h2.std {
	font-size: _rem(36px);
	letter-spacing: _rem(-1.1px);
	line-height: _rem(40px);	// Almost 1.1
}

h3.std, .h3.std {
	font-size: _rem(30px);
	letter-spacing: _rem(-0.9px);
	line-height: _rem(33px);	// Almost 1.1
}

h4.std, .h4.std {
	font-size: _rem(25px);
	letter-spacing: _rem(-0.8px);
	line-height: _rem(28px);	// Almost 1.1
}

h5.std, .h5.std {
	font-size: _rem(21px);
	letter-spacing: _rem(-0.6px);
	line-height: _rem(23px);	// Almost 1.1
}

h6.std, .h6.std {
	font-size: _rem(18px);
	letter-spacing: _rem(-0.4px);
	line-height: _rem(20px);	// Almost 1.1
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
	&.std {
		font-weight: 700;

		&.light {
			font-weight: 300;
		}

		&.medium {
			font-weight: 500;
		}

		&.strong {
			font-weight: 600;
		}

		&.no-margin {
			margin: 0;
		}
	}
}


/* TYPOGRAPHY
--------------------------------------*/
h1, h2, h3, h4, h5, h6 {
	line-height: 1.3;
}

h1 {
	font-size: _rem(36px);
}

h2 {
	font-size: _rem(30px);
}

h3 {
	font-size: _rem(24px);
}

h4 {
	font-size: _rem(18px);
}

h5 {
	font-size: _rem(14px);
}

h6 {
	font-size: _rem(12px);
}
