$baseline-px    : 16px;
$scrollbar-width: 7px;

$gutter-width      : 5px;
$gutter-width-fixed: 5px;
$gutter-width-2x   : $gutter-width * 2;
$gutter-width-4x   : $gutter-width * 4;

// Orange color
$color-orange-r   : 255;
$color-orange-g   : 80;
$color-orange-b   : 0;

// Colors
$color-green          : #42AD65;
$color-orange         : #FF5000;
$color-red            : #D50F06;
$color-yellow         : #D50F06;
$color-blue           : #00A2C1;       // Used for Portal Select
$color-black          : #000000;
$color-white          : #FFFFFF;

// Gray colors
$color-lightest-gray  : #F6F7F9;
$color-lighter-gray   : #E8E9ED;
$color-new-light-gray : #DBDCE0;
$color-faded-gray     : #AEB0B5;
$color-gray           : #8E9197;
$color-medium-gray    : #73757B;
$color-dark-gray      : #595A5E;
$color-darker-gray    : #3A3A3F;
$color-charcoal       : #27272A;

$color-text           : $color-gray;

// Buttons
$color-button-primary-orange               : #FF5000;
$color-button-primary-orange-pressed       : #D24C1F;
$color-button-primary-outline-orange-hover : #F07322;

$color-button-secondary-grey               : #8A8A8D;
$color-button-secondary-grey-pressed       : #727277;
$color-button-secondary-outline-grey       : $color-medium-gray;
$color-button-secondary-outline-border-grey: $color-new-light-gray;
$color-button-secondary-outline-grey-hover : $color-gray;

$color-button-green                        : #42AD65;
$color-button-green-pressed                : #39a05a;
$color-button-outline-green-hover          : #4dca75;

$color-body-background                     : $color-lightest-gray;


// Standard colors
$std-border-color  : $color-new-light-gray;
$std-button-radius : _rem(40px);
$std-border-radius : _rem(8px);
$std-shadow        : 0 3px 10px 0 rgba(0, 0, 0, 0.05);
$color-radio-border: $color-faded-gray;

// Change Orders Colors
$change-order-action-colors: ( #4E86FA, #8C33E7, #22C1B0, #F38800, #E92E50, #1FCA41, #33A9CE, #9DBD00, #E73DCC, #003CBD, #4E0090, #007729, #AF0141, #80A8D5, #B78EE0, #7FD38F );
